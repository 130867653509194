<template>
  <footer
    class="relative bg-gray-100 pt-8 pb-6"
    style="font-family: Raleway, sans-serif; font-weight: 700"
  >
    <div>
      <section class="footer-img p-lg-5 pb-2 pt-4 pl-4 pr-2">
        <b-row class="w-100 pb-5 pl-lg-5 ml-lg-3 pr-lg-5 mr-lg-3">
          <b-col cols="12" sm="1" class=""></b-col>
          <b-col cols="12" sm="3" class="">
            <h4 class="pb-4 text-white">
              EXPODATABOX
              <hr style="width: 40%; margin-top: 5px" class="text-white" />
            </h4>
            <p class="text-white">
              Expodatabox provides up-to-date information about overseas
              importers and exporters.
            </p>
          </b-col>
          <b-col cols="12" sm="2">
            <h4 class="pb-4 text-white">
              Quick Links
              <hr style="width: 50%; margin-top: 5px" class="text-white" />
            </h4>
            <ul class="pl-1 text-white">
              <li class="list-none"><a href="/#testimonial">Testimonial</a></li>
              <li class="list-none"><a href="/#Inquiry">Support</a></li>
              <li class="list-none"><a href="/#plans">Pricing</a></li>
              <li class="list-none"><router-link to="/termsconditions">Terms & Conditions</router-link></li>
              <li class="list-none"><router-link to="/privacy-policy">Privacy Policy </router-link></li>
              <li class="list-none">
                <a href="/#aboutus">About Expodatabox</a>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" sm="2">
            <h4 class="pb-4 text-white">
              Our Plans
              <hr style="width: 40%; margin-top: 5px" class="text-white" />
            </h4>
            <ul class="pl-1 text-white">
              <li class="list-none"><a href="/#plans">Trial</a></li>
              <li class="list-none"><a href="/#plans">Silver</a></li>
              <li class="list-none"><a href="/#plans">Platinium</a></li>
              <li class="list-none"><a href="/#plans">Gold</a></li>
            </ul>
          </b-col>
          <b-col cols="12" sm="3">
            <h4 class="pb-4 text-white">
              Contact Us
              <hr style="width: 40%; margin-top: 5px" class="text-white" />
            </h4>
            
            <b-row>
              <p class="text-white">
                <b-icon icon="envelope"></b-icon>
                <a href="mailto:support@expodatabox.com">
                  support@expodatabox.com</a
                >
              </p>
            </b-row><b-row>
              <p class="text-white">
                <b-icon icon="phone"></b-icon>
                <a href="tel:8780604705">
                  (+91) 878-060-470-5</a
                >
              </p>
            </b-row>
            <b-row>
              <p class="text-white" >
                <b-icon icon="map"></b-icon> Ahmedabad, India 
              </p>
            </b-row>
            <b-row class="">
              <b-col cols="3" sm="2" class="pr-0 mr-0">
                <a href="https://www.facebook.com/expodatabox/" target="blank" style="border:none;"><b-avatar
                  size="40"
                  icon="facebook"
                  class="text-white"
                                    variant="outline-primary"

                ></b-avatar></a>
              </b-col>
              <b-col cols="3" sm="2" class="pr-0 pl-0 mr-0">
                 <a href="https://www.instagram.com/expodatabox/" target="blank" style="border:none;">
                <b-avatar
                  size="40"
                  variant="outline-primary"
                  icon="instagram"
                ></b-avatar>
                </a>
              </b-col>
              <!-- <b-col cols="3" sm="2" class="pr-0 mr-0">
                <b-avatar
                  size="40"
                  variant=""
                  icon="linkedin"
                  class="text-white"
                ></b-avatar>
              </b-col> -->
              <b-col cols="12" sm="1" class=""></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col class="text-center">
            <b-navbar toggleable="lg" type="dark" class="w-100">
              <!-- Right aligned nav items -->
              <b-navbar-nav class="w-100">
                <b-navbar-nav class="w-100">
                  <p class="w-100 nav-link123">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      class="img-fluid12"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      fill="white"
                      x="0px"
                      y="0px"
                      viewBox="0 0 367.467 367.467"
                      style="enable-background: new 0 0 367.467 367.467"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          d="M183.73,0.018C82.427,0.018,0,82.404,0,183.733c0,101.289,82.427,183.716,183.73,183.716
		c101.315,0,183.737-82.427,183.737-183.716C367.467,82.404,285.045,0.018,183.73,0.018z M183.73,326.518
		c-78.743,0-142.798-64.052-142.798-142.784c0-78.766,64.055-142.817,142.798-142.817c78.752,0,142.807,64.052,142.807,142.817
		C326.536,262.466,262.481,326.518,183.73,326.518z"
                        />
                        <path
                          d="M244.036,217.014c-11.737,20.141-33.562,32.635-56.956,32.635c-36.329,0-65.921-29.585-65.921-65.915
		c0-36.36,29.592-65.955,65.921-65.955c23.395,0,45.219,12.54,56.956,32.641l1.517,2.627h44.28l-2.658-7.129
		c-7.705-20.413-21.225-37.769-39.122-50.157c-17.942-12.42-39.017-19.009-60.973-19.009c-58.981,0-106.946,48.006-106.946,106.982
		c0,58.98,47.965,106.941,106.946,106.941c21.956,0,43.03-6.567,60.973-19.006c17.897-12.391,31.417-29.741,39.122-50.154
		l2.658-7.133h-44.28L244.036,217.014z"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    2021 All Rights Reserved By ExpoDataBox
                  </p>
                </b-navbar-nav>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
      </section>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
       

    return {
      date: new Date().getFullYear(),
      
    };
  },
};
</script>
<style scoped>
a {
  color: white;
  text-decoration-line: none;
}

a:hover {
  border-bottom: white 1px solid;
  color: #fa2b56;
}
.mrnegative {
  margin-left: -4px;
}
.footer-img {
  background-image: url(/img/Footer-Background-2.webp);
}
.widthicon {
  width: 10%;
}
.backblue {
  background: #1e38a7;
}
.nav-link123 {
  color: #d1d1d1 !important;
}
.nav-link {
  color: white !important;
}
.ml-40 {
  margin-left: 37.5% !important;
}
.img-fluid12 {
  width: 1.5%;
  height: auto;
}
.img-fluid12 path {
  fill: white !important;
}
.list-none {
  list-style: none;
  margin-bottom: 2%;
}
@media only screen and (max-width: 375px) {
  .mrnegative {
    margin-left: 4%;
    margin-bottom: 2%;
  }
  .bg-contact {
    background-size: auto;
  }
}
</style>
